//! Fathem Tracking - Add an id to the button in the backend then reference below
/*
const CHANGE_THIS_VARIABLE = document.querySelectorAll("[data-name = ADD DATA NAME]");
if (CHANGE_THIS_VARIABLE.length > 0) {
    CHANGE_THIS_VARIABLE[0].addEventListener("click", function () {
        fathom.trackEvent('EVENT_CODE');

    });
} */
const chamoun = Array.from(document.querySelectorAll("[data-name = Chamoun-IL-18-aacr]")) ? Array.from(document.querySelectorAll("[data-name = Chamoun-IL-18-aacr]")) : [];;
if (chamoun.length > 0) {
    chamoun[0].addEventListener("click", function () {
        fathom.trackEvent('Chamoun_IL_18_AACR');
    });
}

const chamoun_disclosures = Array.from(document.querySelectorAll("[data-name = Chamoun-IL-18-disclosures]")) ? Array.from(document.querySelectorAll("[data-name = Chamoun-IL-18-disclosures]")) : [];;
if (chamoun_disclosures.length > 0) {
    chamoun_disclosures[0].addEventListener("click", function () {
        fathom.trackEvent('Chamoun_IL_18_Disclosures');
    });
}

const freedland_poster = Array.from(document.querySelectorAll("[data-name = freedland-poster]")) ? Array.from(document.querySelectorAll("[data-name = freedland-poster]")) : [];
if (freedland_poster.length > 0) {
    freedland_poster[0].addEventListener("click", function () {
        fathom.trackEvent('APA_EMUC_Freedland_POSTMET_POSTER');
    });
}

const krabbe_poster = Array.from(document.querySelectorAll("[data-name = krabbe-poster]")) ? Array.from(document.querySelectorAll("[data-name = krabbe-poster]")) : [];;
if (krabbe_poster.length > 0) {
    krabbe_poster[0].addEventListener("click", function () {
        fathom.trackEvent('ISPOR-EU_APA_Krabbe_Curative-Intent_POSTER');
    });
}

const krabbe_supplement = Array.from(document.querySelectorAll("[data-name = krabbe-supplement]")) ? Array.from(document.querySelectorAll("[data-name = krabbe-supplement]")) : [];;
if (krabbe_supplement.length > 0) {
    krabbe_supplement[0].addEventListener("click", function () {
        fathom.trackEvent('ISPOR-EU_APA_Krabbe_Curative-Intent_SUPPLEMENT');
    });
}

const ispor_freedland_poster = Array.from(document.querySelectorAll("[data-name = ispor-freedland-poster]")) ? Array.from(document.querySelectorAll("[data-name = ispor-freedland-poster]")) : [];;
if (ispor_freedland_poster.length > 0) {
    ispor_freedland_poster[0].addEventListener("click", function () {
        fathom.trackEvent('ISPOR-EU_Apa_Freedland_POSTMET_POSTER');
    });
}

const heiber_poster = Array.from(document.querySelectorAll("[data-name = aacr-Heiber-IL-12]")) ? Array.from(document.querySelectorAll("[data-name = aacr-Heiber-IL-12]")) : [];;
if (heiber_poster.length > 0) {
    heiber_poster[0].addEventListener("click", function () {
        fathom.trackEvent('Heiber-IL-12_Poster_AACR');
    });
}

const heiber_disclosures = Array.from(document.querySelectorAll("[data-name = aacr-Heiber-IL-12-disclosures]")) ? Array.from(document.querySelectorAll("[data-name = aacr-Heiber-IL-12-disclosures]")) : [];;
if (heiber_disclosures.length > 0) {
    heiber_disclosures[0].addEventListener("click", function () {
        fathom.trackEvent('Heiber-IL-12_Disclosures');
    });
}