const siteHost = window.location.host;
const links = Array.from(document.querySelectorAll("a"));

const modal = document.querySelector(".modal");
const modalClose = document.querySelector(".modal__close");
const modalButton = document.querySelector(".modal__button");

const isExternal = (link) => {
    if (link.host === "null") return;
    if (!link.host.includes(siteHost)) link.setAttribute("data-external", ""); // if anchor's href attribute doesn't contain the PRR's origin in it's URL
};

const isEmail = (link) => {
    if (link.host === "null") return;
    if (link.href.includes("mailto")) link.removeAttribute("data-external", ""); // if anchor's href is set to an email
};

const isPhone = (link) => {
    if (link.host === "null") return;
    if (link.href.includes("tel")) link.removeAttribute("data-external", ""); // if anchor's href is set to a phone number
};

const handleExternalLinkClick = (e) => {
    e.preventDefault();
    toggleModal(e.target.href);
};

const toggleModal = (url = null) => {
    document.body.classList.toggle("modal-active");
    modal.classList.toggle("modal--active");
    if (url !== null) modalButton.href = url;
};

if (links.length > 0) {
    links.forEach((link) => isExternal(link));
    links.forEach((link) => isEmail(link));
    links.forEach((link) => isPhone(link));

    const externalLinks = Array.from(document.querySelectorAll("a[data-external]"));

    if (externalLinks.length > 0) {
        if (modal != null) {
            externalLinks.forEach((link) => {
                link.addEventListener("click", (e) => handleExternalLinkClick(e));
            });

            modalClose.addEventListener("click", () => toggleModal());
            modalButton.addEventListener("click", () => toggleModal());
        }
    }
}

if (modal != null) {
    if (document.getElementsByClassName("modal")[0].getAttribute("study-modal")) {
        if (
            document.querySelector("[study-modal]").getAttribute("study-modal")
                .length > 1
        ) {
            const modalCopyText = document.querySelector("[study-modal]");
            let ModalCopy = document.getElementById("modalCopy");
            ModalCopy.textContent = modalCopyText.getAttribute("study-modal");
        }
    }
}
