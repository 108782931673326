const accordion = document.querySelector('.accordions')
  ? document.querySelector('.accordions')
  : null;

if (accordion !== null) {
  const buttons = Array.from(
    document.querySelectorAll('.accordions__accordion__label')
  );

  buttons.forEach((button) => {
    button.addEventListener('click', ({ target }) => {
      console.log(target);
      if (
        target.classList.contains('switch__checkbox') ||
        target.classList.contains('switch__label')
      )
        return;
      target.classList.toggle('accordions__accordion__label--active');
    });
  });
}
