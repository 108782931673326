const body = document.querySelector("html");
const accessibilityButton = document.querySelector(".accessibility");
const fontSize1Buttons = document.querySelectorAll(".font-size__1");
const fontSize2Buttons = document.querySelectorAll(".font-size__2");
const fontSize3Buttons = document.querySelectorAll(".font-size__3");
const contrast1Buttons = document.querySelectorAll(".standard-contrast");
const contrast2Buttons = document.querySelectorAll(".low-contrast");
const contrast3Buttons = document.querySelectorAll(".high-contrast");
let options = {
    contrast1: 0,
    contrast2: 0,
    contrast3: 0,
    fontSize1: 0,
    fontSize2: 0,
    fontSize3: 0,
};

const setStorage = (options) => {
    sessionStorage.setItem("accessibilityOptions", options);
};

const getStorage = () => {
    if (sessionStorage.getItem("accessibilityOptions")) {
        return (options = JSON.parse(sessionStorage.getItem("accessibilityOptions")));
    }
};

const resetOptions = (isFont) => {
    if (isFont === true) {
        options.fontSize1 = 0;
        options.fontSize2 = 0;
        options.fontSize3 = 0;
    } else {
        options.contrast1 = 0;
        options.contrast2 = 0;
        options.contrast3 = 0;
    }
};

const setOption = (optionName) => {
    options[optionName] = 1;
};

const getActiveOptions = () => {
    let entries = Object.entries(options);
    const activeOptions = entries.filter((entry) => entry[1] === 1);
    return activeOptions;
};

const removeStyles = (isFont) => {
    if (isFont === true) {
        body.classList.remove("font-size__1");
        body.classList.remove("font-size__2");
        body.classList.remove("font-size__3");
    } else {
        body.classList.remove("standard-contrast");
        body.classList.remove("low-contrast");
        body.classList.remove("high-contrast");
    }
};

const setStyles = () => {
    getActiveOptions().forEach((option) => {
        const optionName = option[0];

        switch (optionName) {
            case "fontSize1":
                removeStyles(true);
                body.classList.add("font-size__1");
                break;
            case "fontSize2":
                removeStyles(true);
                body.classList.add("font-size__2");
                break;
            case "fontSize3":
                removeStyles(true);
                body.classList.add("font-size__3");
                break;
            case "contrast1":
                removeStyles(false);
                body.classList.add("standard-contrast");
                break;
            case "contrast2":
                removeStyles(false);
                body.classList.add("low-contrast");
                break;
            case "contrast3":
                removeStyles(false);
                body.classList.add("high-contrast");
                break;

            default:
                break;
        }
    });
};

fontSize1Buttons.forEach((fontSize1) => {
    fontSize1.addEventListener("click", () => {
        resetOptions(true);
        setOption("fontSize1");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});
fontSize2Buttons.forEach((fontSize2) => {
    fontSize2.addEventListener("click", () => {
        resetOptions(true);
        setOption("fontSize2");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});
fontSize3Buttons.forEach((fontSize3) => {
    fontSize3.addEventListener("click", () => {
        resetOptions(true);
        setOption("fontSize3");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});
contrast1Buttons.forEach((contrast1) => {
    contrast1.addEventListener("click", () => {
        resetOptions(false);
        setOption("contrast1");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});
contrast2Buttons.forEach((contrast2) => {
    contrast2.addEventListener("click", () => {
        resetOptions(false);
        setOption("contrast2");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});
contrast3Buttons.forEach((contrast3) => {
    contrast3.addEventListener("click", () => {
        resetOptions(false);
        setOption("contrast3");
        setStorage(JSON.stringify(options));
        setStyles();
    });
});

getStorage();
setStyles();

const languageSelectors = document.querySelector(".header__bar__accessibility__button")
    ? Array.from(document.querySelectorAll(".header__bar__accessibility__button"))
    : null;

if (languageSelectors !== null) {
    languageSelectors.forEach((languageSelector) =>
        languageSelector.addEventListener("click", ({ target }) => {
            if (target && target.matches(".header__bar__accessibility__button")) {
                target.classList.toggle("header__bar__accessibility__button--active");
            }
            const dropdown = document.getElementsByClassName(
                "header__bar__accessibility__dropdown"
            )[1];
            if (dropdown) {
                switch (dropdown.style.display) {
                    case "block":
                        dropdown.style.display = "none";
                        break;

                    case "none":
                        dropdown.style.display = "block";
                        break;
                }
            }
        })
    );
}

// window.onload = (event) => {
//   document.getElementsByClassName(
//     "header__bar__accessibility__dropdown"
//   )[0].style.display = "none";
//   document.getElementsByClassName(
//     "header__bar__accessibility__dropdown"
//   )[1].style.display = "none";
// };

const mainAccButton = document.querySelector(".header__bar__accessibility__button");
const accDropDown = document.querySelector(".header__bar__accessibility__dropdown");

function displayBlock() {
    accDropDown.classList.toggle("Displaythis");
}

if (mainAccButton) {
    mainAccButton.addEventListener("click", displayBlock);
}

const mainAccButtonCP = document.querySelector(".cp-btn");
const accDropDownCP = document.querySelector(".cp");

function displayBlockCP() {
    accDropDownCP.classList.toggle("Displaythis");
}

if (mainAccButtonCP) {
    mainAccButtonCP.addEventListener("click", displayBlockCP);
}
