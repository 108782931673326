// Need to update to take more than one option. Just in case they add more cookies for marketing. Currently sety up ONLY for new gtag codes

const cookiePanel = document.getElementById("cookiePanel");
const cookies = document.cookie;
const header = document.getElementById("header");
const head = document.getElementsByTagName("head")[0];

const allowAllCookies = document.getElementById("allCookies")
    ? document.getElementById("allCookies")
    : Array();
const necessaryOnly = document.getElementById("necessaryCookies")
    ? document.getElementById("necessaryCookies")
    : Array();
const cookiePreferences = document.getElementById("cookiePreferences")
    ? document.getElementById("cookiePreferences")
    : Array();
const confirmPreferences = document.getElementById("confirmPreferences")
    ? document.getElementById("confirmPreferences")
    : Array();
const cookieReset = document.getElementsByClassName("cookie-reset")
    ? document.getElementsByClassName("cookie-reset")
    : Array();

const cookieSwitchs = Array.from(document.querySelectorAll(".switch__label"));
const cookieOptions = {
    gtag: false,
};

/**
 * Make an object with all the cookie switched with a default value of false then when it is ticked, overwrite it's value with true
 */
if (cookieSwitchs) {
    cookieSwitchs.forEach((cookieSwitch) => {
        cookieSwitch.addEventListener("click", (e) => {
            const accordion = e.target.parentElement.parentElement.parentElement;
            const switchPrenet = e.target.parentElement;
            const checkbox = switchPrenet.querySelector(".switch__checkbox");
            const checkboxFor = checkbox.getAttribute("for");
            checkbox.checked
                ? (cookieOptions.gtag = false)
                : (cookieOptions.gtag = true);
            const customCookies = Array.from(
                accordion.querySelectorAll("[data-cookie]")
            );
        });
    });
}

// Gets the cookies as array of arrays
const keys = document.querySelector("[data-keys]").dataset.keys;
const keyLibrary = [];

// Splits Keys array of arrays into one array of objects {key: 'value'}
keys.split(", ").forEach((key) => {
    const keyObj = {};
    keyObj.key = key.split(": ")[0];
    keyObj.value = key.split(": ")[1];
    keyLibrary.push(keyObj);
});

const parseCookie = (str) => {
    if (str.length > 0) {
        str.split(";")
            .map((v) => v.split("="))
            .reduce((acc, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
                    v[1].trim()
                );
                return acc;
            }, {});
    }
};

/**
 * Creats a script element and inserts gtag into it
 * @param {string} code This takes the input of `code` and puts it into a script tage
 * @return {object} -
 */
const buildScript = (code, src = null) => {
    const script = document.createElement("script");

    if (code !== null) {
        script.textContent = code;
    } else {
        script.setAttribute("src", src);
    }

    return script;
};

const buildGtag = (keys) => {
    keys.forEach((key) => {
        switch (key.key) {
            case "gtag":
                let id = key.value;
                const src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
                const code = `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${id}');`;

                head.appendChild(buildScript(null, src));
                head.appendChild(buildScript(code));
                break;
            default:
                break;
        }
    });
};

const removeGtagCookies = () => {
    for (const cookie in parseCookie(document.cookie)) {
        if (cookie.includes("gi") || cookie.includes("ga")) {
            removeCookie(cookie);
        }
    }
};

// Set/Get/Delete Cookies
const setSessionCookie = (name, value = "cookie") => {
    document.cookie = `${name}=${value}; expires=session; path=/;`;
};

const removePXLCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const domain = window.location.hostname;

const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.${domain}`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.tannwestlake.net`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;
};

const getCookie = (name) => {
    var siteCookie = document.cookie;
    var prefix = name + "=";
    var begin = siteCookie.indexOf("; " + prefix);
    if (begin == -1) {
        begin = siteCookie.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = siteCookie.length;
        }
    }
    return decodeURI(siteCookie.substring(begin + prefix.length, end));
};
// End Set/Get/Delete Cookies

const popupCheck = () => {
    if (!getCookie("pxlCookieConsent")) {
        cookiePanel.classList.add("cookie-panel--add");
    }
};

switch (getCookie("pxlCookieConsent")) {
    case "allowAll":
        buildGtag(keyLibrary);
        break;
    case "true":
        buildGtag(keyLibrary);
    default:
        break;
}

// Button Event Listeners
if (cookiePanel) {
    cookiePreferences.addEventListener("click", () => {
        const dropdown = cookiePanel.querySelector(".cookie-panel__accordions");
        const span = cookiePanel.querySelector(
            ".cookie-panel__banner__content__buttons__link__icon"
        );
        cookiePanel.classList.toggle("cookie-panel--active");
        dropdown.classList.toggle("cookie-panel__accordions--active");
        span.classList.toggle(
            "cookie-panel__banner__content__buttons__link__icon--active"
        );
    });

    allowAllCookies.addEventListener("click", () => {
        buildGtag(keyLibrary);
        setSessionCookie("pxlCookieConsent", "allowAll");
        cookiePanel.classList.remove("cookie-panel--add");
        setTimeout(() => {
            location.reload();
        }, 800);
    });

    necessaryOnly.addEventListener("click", () => {
        removeGtagCookies();
        setSessionCookie("pxlCookieConsent", "necessaryOnly");
        cookiePanel.classList.remove("cookie-panel--add");
        location.reload();
    });

    confirmPreferences.addEventListener("click", () => {
        setSessionCookie("pxlCookieConsent", `${cookieOptions.gtag}`);
        if (getCookie("pxlCookieConsent") == true) {
            buildGtag(keyLibrary);
        }
        cookiePanel.classList.remove("cookie-panel--add");
        setTimeout(() => {
            location.reload();
        }, 800);
    });

    if (cookieReset[0]) {
        cookieReset[0].addEventListener("click", () => {
            removeGtagCookies();
            removePXLCookie("pxlCookieConsent");
            location.reload();
        });
    }

    popupCheck();
}
